import { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import GSOCApiFactory from '../../gsoc-api/GSOCApiFactory';
import { ListRolesResponseContent, RoleSummary } from '../../gsoc-api/generated-src';

export default function FormPage() {
  const history = useHistory();
  const {id, mode} = useParams<{id: string, mode: string}>();
  const roleName = parseInt(id);
  const initialRoleSummaryData: RoleSummary = {
    roleName: 'fake',
    permissionLevel: 'fake',
    isAuthorized: false,
    roleArn: 'fake',
  };
  const [roleSummaryData, setRoleSummaryData] = useState(initialRoleSummaryData);
  const GSOCApi = GSOCApiFactory();

  return (
    <>  
      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 6, s: 6, xxs: 8 }, offset: { s: 3, xxs: 1 } }
          ]}
        >
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" href="#/">Cancel</Button>
              </SpaceBetween>
            }
            header={
              <Header variant="h1" description="Role information">
                Pet
              </Header>
            }
          >
            <Container >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="roleName" stretch>
                  <Input
                    value={roleSummaryData.roleName!}
                    ariaRequired={true}
                    placeholder="roleName"
                  />
                </FormField>
                <FormField label="permissionLevel">
                  <Input
                    value={roleSummaryData.permissionLevel!}
                    ariaRequired={true}
                    placeholder="permissionLevel"
                  />
                </FormField>
                <FormField label="isAuthorized">
                  <Input
                    value={roleSummaryData.isAuthorized!.toString()}
                    ariaRequired={true}
                    placeholder='false'
                  />
                </FormField>
                <FormField label="roleArn">
                  <Input
                    value={roleSummaryData.roleArn!}
                    ariaRequired={true}
                    placeholder='roleArn'
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
        </Grid>
      </Box>
    </>
  );
}
